export const getFeatureFlags = () => ({});

export const getbreadCrumbSteps = localization => {
  const breadcrumbSteps = [
    [
      {
        name: localization && localization.services,
        pathname: '/',
      },
      {
        name: localization && localization.backServiceList,
        pathname: '/addService',
        fallBack: '/',
        enableBackLink: true,
      },
      {
        name: localization && localization.backServiceListEdit,
        pathname: '/edit-service',
        fallBack: '/',
        enableBackLink: true,
      },
    ],
  ];

  const breadcrumbProps = {
    stepsArray: breadcrumbSteps,
    validationElementName: '#user-form',
  };

  return breadcrumbProps;
};

export const isSupportSingleLanguage = (user, isUserLoaded) => {
  // Get user token
  if (isUserLoaded) {
    // eslint-disable-next-line prefer-destructuring
    const countriesSupportSingleLanguage = window.countriesSupportSingleLanguage;
    return countriesSupportSingleLanguage.includes(user.CountryId);
  }
  return true;
};

export const getCurrencyLocalizedName = (countries, user, language) => {
  let localizedCurrency;
  if (countries && countries.length && user) {
    const { CountryId } = user;
    const country = countries.filter(countryObj => countryObj.CountryId === CountryId);
    const languageId = language === 'en' ? 1 : 2;
    if (country && country[0]) {
      const { CountryCourrencies } = country[0];
      const currencyModel = CountryCourrencies.CurrencyModels.filter(
        model => model.IsBillingCurrency,
      );
      if (currencyModel && currencyModel[0]) {
        const currencyLanguageModel = currencyModel[0].LanguageItemModels.filter(
          model => model.LanguageId === languageId,
        );
        if (currencyLanguageModel && currencyLanguageModel[0]) {
          localizedCurrency = currencyLanguageModel[0].Name;
        }
      }
    }
  }
  return localizedCurrency;
};
