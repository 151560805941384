import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FeatureFlagContainer, SetGlobalStyle } from '@vezeeta/web-components';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RoleBasedRoute from 'app/RoleBasedAccess/RoleBasedRoute';
import EnterpriseLayout from '@vezeeta/enterprise-layout';
import { connect } from 'react-redux';
import { MixpanelConsumer } from 'react-mixpanel';
import '@vezeeta/enterprise-modals/lib/Modal.css';
import { language } from '@vezeeta/enterprise-store/lib/types/language';
import { withTranslation } from 'app/withTranslation/withTranslation';
import { ServicesList, AddService, EditService } from 'app/Routes';
import '@vezeeta/web-components/lib/shared/Utils.css';
import {
  getFeatureFlags,
  getbreadCrumbSteps,
  isSupportSingleLanguage,
  getCurrencyLocalizedName,
} from './App.helper';
import { extendContentContainer } from './App.style';

const App = ({ ...props }) => {
  useEffect(() => {
    SetGlobalStyle();
  }, []);

  const { user, isUserLoaded, clinics, localization, countries } = props;
  const supportSingleLanguage = isSupportSingleLanguage(user, isUserLoaded);
  let localizedCurrency = '';
  if (!localizedCurrency && countries && user && language) {
    localizedCurrency = getCurrencyLocalizedName(countries, user, language);
  }

  return (
    <FeatureFlagContainer.Provider value={getFeatureFlags()}>
      <Router>
        <EnterpriseLayout
          supportSingleLanguage={supportSingleLanguage}
          breadcrumbProps={getbreadCrumbSteps(localization)}
          activeTab="Services"
          extendContentContainer={extendContentContainer}
        >
          <React.Fragment>
            <MixpanelConsumer>
              {mixpanel => (
                <Switch>
                  <RoleBasedRoute
                    path="/"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <ServicesList
                        {...routeProps}
                        {...props}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        clinics={clinics}
                        localization={localization}
                        language={language}
                        localizedCurrency={localizedCurrency}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/addService"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <AddService
                        {...routeProps}
                        {...props}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        clinics={clinics}
                        localization={localization}
                        language={language}
                        localizedCurrency={localizedCurrency}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/edit-service"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <EditService
                        {...routeProps}
                        {...props}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        clinics={clinics}
                        localization={localization}
                        language={language}
                        localizedCurrency={localizedCurrency}
                      />
                    )}
                  />
                </Switch>
              )}
            </MixpanelConsumer>
          </React.Fragment>
        </EnterpriseLayout>
      </Router>
    </FeatureFlagContainer.Provider>
  );
};

const mapStateToProps = state => ({
  isUserLoaded: state.user.loaded,
  user: state.user.user,
  countries: state.country.Countries,
  clinics: state.clinics.Clinics,
});

App.propTypes = {
  isUserLoaded: PropTypes.bool.isRequired,
  user: PropTypes.object,
  clinics: PropTypes.array,
  localization: PropTypes.object,
};

export default withTranslation(connect(mapStateToProps, null)(App));
