import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

export const ServicesList = Loadable({
  loader: () => import('views/ServicesListContainer'),
  loading: Loading,
});

export const AddService = Loadable({
  loader: () => import('views/AddServiceContainer'),
  loading: Loading,
});

export const EditService = Loadable({
  loader: () => import('views/EditServiceContainer'),
  loading: Loading,
});
